/********************************
 * PFM(통합플랫폼) API 스크립트
 ********************************/
import { callApi } from '~/api/pfm';

/**
 * 로그인 사용자정보 조회
 * @returns
 */
export const sessionInfo = async (progress = true) => {
  return await callApi({ url: '/anony/sessionInfo', progress }, {});
};

/**
 * 로그인세션체크
 * @returns
 */
export const loginSessionCheck = async (progress = false) => {
  return await callApi({ url: '/anony/loginSessionCheck', progress }, {}, true);
};
