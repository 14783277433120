/********************************
 * MDP(마이데이터) API 스크립트
 ********************************/
import { callMdpApi } from '~/api/mdp';
import { callApi } from '~/api/pfm';

export const getNonce = async (data, context = window?.$nuxt?.context) => {
  return await callMdpApi('/uauth/nonce', data);
};

export const getOrgNonceList = async (data, context = window?.$nuxt?.context) => {
  return await callMdpApi('/uauth/org-nonce-list', data);
};

export const getOrgsScope = async (orgCodeList, context = window?.$nuxt?.context) => {
  return await callMdpApi('/uauth/orgs/scope', {
    orgCodeList,
  });
};

/**
 * 통합인증 (1차)
 * @param data
 * @param context
 * @returns {Promise<CommonResponse>}
 */
export const getFirstToken = async (data, context = window?.$nuxt?.context) => {
  return await callMdpApi({ url: '/uauth/first-token', progress: false }, data, true);
};

/**
 * 통합인증 (2차)
 * @param data
 * @param context
 * @returns {Promise<CommonResponse>}
 */
export const getSecondToken = async (data, context = window?.$nuxt?.context) => {
  return await callMdpApi({ url: '/uauth/second-token', progress: false }, data, true);
};

/**
 * 통합수집요청 API
 * @param data
 * @param context
 * @returns {Promise<CommonResponse>}
 */
export const collect = async (data, context = window?.$nuxt?.context) => {
  return await callMdpApi('/collect', data);
};

/**
 * 통합수집요청 상태조회 API
 * @param data
 * @param context
 * @returns {Promise<CommonResponse>}
 */
export const getCollectStatus = async (data, context = window?.$nuxt?.context) => {
  return await callMdpApi({ url: '/collect/status', progress: false }, data, true);
};

export const getCollectStatusLoading = async (data, context = window?.$nuxt?.context) => {
  return await callMdpApi({ url: '/collect/status', progress: true }, data, true);
};

/**
 * 통합수집요청 결과적재 API
 * @param data
 * @param context
 * @returns {Promise<CommonResponse>}
 */
export const getCollectResult = async (data, context = window?.$nuxt?.context) => {
  return await callMdpApi({ url: '/collect/result', progress: false }, data, true);
};

export const collectByOrg = async (data, context = window?.$nuxt?.context) => {
  return await callMdpApi('/collect/by-org', data, true);
};

export const collectByOrgList = async (data, context = window?.$nuxt?.context) => {
  return await callMdpApi('/collect/async/by-org', data);
};

export const collectOndemand = async (data, context = window?.$nuxt?.context) => {
  return await callMdpApi('/collect/on-demand', data, true);
};

export const getAssetConnBurList = (progress = false) => {
  return callMdpApi('/asset-conn/bur-list', {});
};

/**
 * 기관목록 조회 (공통에러처리안함)
 * @returns {Promise<CommonResponse>}
 */
export const getAssetConnBurListTrue = (progress = false) => {
  return callMdpApi('/asset-conn/bur-list', {}, true);
};

export const getAsstList = async (data, progress = false) => {
  return await callMdpApi({ url: '/asset-conn/asst-list', progress: false }, data, true);
};

export const joinMydata = async (data, progress = false) => {
  return await callMdpApi('/mydata-member/join', data);
};

export const privateSignRequest = async (data, progress = false) => {
  return await callMdpApi('/uauth/private/sign-request', data, true);
};

export const privateSignResult = async (data, progress = false) => {
  return await callMdpApi('/uauth/private/sign-result', data, true);
};

/**
 * 오픈뱅킹 탈회 처리
 * @param {Object} data 요청 data {
 *   mnyBlamCheckYn(머니잔액체크여부): 'Y'(모니머니잔액체크O)/'N'(모니머니잔액체크X)
 * }
 * @returns prcsOpbkSoMdp
 */
export const prcsOpbkSoMdp = async data => {
  return await callApi('/restapi/opb/assetbase/prcsOpbkSo', data, true);
};
