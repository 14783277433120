/********************************
 * PFM(통합플랫폼) API 스크립트
 ********************************/
import { callApi } from '~/api/pfm';

/********************************
 * PFM(통합플랫폼) API 스크립트
 *  - 임시로그인
 *
 * @param {String} reqData 요청데이타
 * @param {Boolean} dontHandlerErrors 공통 에러처리를 원하지 않는경우 설정 (기본값=false 로 공통에서 에러 핸들링)
 * @returns
 *******************************/
export const sampleLogin = async (reqData, dontHandlerErrors) => {
  return await callApi('/anony/tempLogin', reqData, dontHandlerErrors);
};

/********************************
 * PFM(통합플랫폼) API 스크립트
 *  - 임시로그인용 모니모고객조회
 *
 * @param {String} reqData 요청데이타
 * @param {Boolean} dontHandlerErrors 공통 에러처리를 원하지 않는경우 설정 (기본값=false 로 공통에서 에러 핸들링)
 * @returns
 *******************************/
export const tempLoginMnmCstInqr = async (reqData, dontHandlerErrors) => {
  return await callApi('/anony/tempLoginMnmCstInqr', reqData, dontHandlerErrors);
};

/********************************
 * PFM(통합플랫폼) API 스크립트
 *  - 임시로그아웃
 *
 * @param {String} reqData 요청데이타
 * @param {Boolean} dontHandlerErrors 공통 에러처리를 원하지 않는경우 설정 (기본값=false 로 공통에서 에러 핸들링)
 * @returns
 *******************************/
export const sampleLogout = async (reqData, dontHandlerErrors) => {
  return await callApi('/anony/tempLogout', reqData, dontHandlerErrors);
};
