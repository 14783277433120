/**
 * 테스트에 사용될 API를 여기에 넣습니다 (운영 반영X)
 */
import { callApi } from '@/api/pfm';

/**
 * 테스트용 화면 메타 정보 목록을 조회한다. (화면 URL 포함)
 * @returns
 */
export const srnListForDev = async srnId => {
  return await callApi('/restapi/cmn/srnListForDev');
};

export const selData = async resultData => {
  return await callApi('/restapi/scf/card/info/selData', resultData);
};

/**
 * 세션 만료 테스트
 */
export const exceptionTest = async srnId => {
  return await callApi('/anony/exceptionTest');
};

/**
 * 관계사로 부터 받은데이터 납부요청
 */
export const selListWdrwAcno = async (reqData, dontHandlerErrors) => {
  return await callApi('/restapi/mny/testSelListWdrw', reqData, dontHandlerErrors);
};

/**
 * 오픈뱅킹 테스트 계정 관련 API
 */
// 오픈뱅킹 테스트계정 적용유저 조회
export const selApyTestOpbUser = async (reqData, dontHandlerErrors) => {
  return await callApi('/restapi/opb/test/selApyTestOpbUser', reqData, dontHandlerErrors);
};

// 오픈뱅킹 테스트 계정 적용
export const setOpbkMember = async (reqData, dontHandlerErrors) => {
  return await callApi('/restapi/mny/setOpbkMember', reqData, dontHandlerErrors);
};

// 오픈뱅킹 테스트 조회
export const selTestOpbUser = async (reqData, dontHandlerErrors) => {
  return await callApi('/restapi/opb/test/selTestOpbUser', reqData, dontHandlerErrors);
};

// 회원 정보 조회
export const selMnmUser = async (reqData, dontHandlerErrors) => {
  return await callApi('/restapi/opb/test/selMnmUser', reqData, dontHandlerErrors);
};

// 선불결제카드번호ID 변경
export const updatePpymStlmCdnoId = async (
  {
    mnmCstMngtNo, // String 모니모고객관리번호
    ppymStlmCdnoId, // String 선불카드아이디
  },
  dontHandlerErrors,
) => {
  const data = { mnmCstMngtNo, ppymStlmCdnoId };
  return await callApi('/anony/updatePpymStlmCdnoId', data, dontHandlerErrors);
};

/**
 * 주니어_회원정책_회원전환정보 변경(개발 테스트 전용)
 * @param {String} data 요청DATA
 * @returns
 */
export const testUpdUserGd = async data => {
  return await callApi('/restapi/jnr/mem/test/testUpdUserGd', data);
};
