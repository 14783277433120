/********************************
 * PFM(통합플랫폼) API 스크립트
 *  - /restapi/cmn/funcply/sentry/{APIs}
 ********************************/
import { callApi } from '@/api/pfm';

/**
 * Redis에서 Sentry 정책 정보를 조회한다.
 * @returns result
 */
export const selSentryPly = async () => {
  return await callApi('/restapi/cmn/funcply/sentry/selSentryPly');
};
