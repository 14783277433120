/********************************
 * MDP(마이데이터) API 스크립트
 * CMN : 공통
 ********************************/
import { callMdpApi } from '~/api/mdp';

/**
 * 마이데이터개인화배너  /cmn/banner
 * @returns
 */
export const searchBanner = async data => {
  return await callMdpApi('/cmn/banner', data, true);
};

/**
 * 마이데이터개인화배너정보조회  /cmn/banner
 * @returns
 */
export const searchBannerInfo = async mdCmnBannSubVOList => {
  return await callMdpApi('/cmn/psnz-banner-info', { mdCmnBannSubVOList }, true);
};

/**
 * 마이데이터개인화배너정보조회  /cmn/banner
 * @returns
 */
export const psnzBannerInfo = mdCmnBannSubVOList => {
  return new Promise(resolve => {
    resolve(callMdpApi({ url: '/cmn/psnz-banner-info', progress: false }, { mdCmnBannSubVOList }, true));
  });
};

/**
 * 통합인증정보 조회  /cmn/uauth-info-get
 * @returns
 */
export const getUAuthInfo = async data => {
  return await callMdpApi('/cmn/uauth-info-get', data, true);
};

/**
 * 통합인증정보 적재  /cmn/uauth-info-put
 * @returns
 */
export const putUAuthInfo = async data => {
  return await callMdpApi('/cmn/uauth-info-put', data, true);
};

/**
 * 푸시처리관련추가정보조회 API /cmn/push/landing
 * @returns
 */
export const getPushLandingUrl = async data => {
  return await callMdpApi('/cmn/push/landing', data, true);
};

/**
 * 마케팅동의여부조회 API /cmn/mrkt-ag-yn
 * @returns
 */
export const getMrktAgYn = async (progress = false) => {
  return await callMdpApi('/cmn/mrkt-ag-yn', {}, true);
};

/**
 * 개인화 배너 조회
 */
export const getPsnzBnnr = async data => {
  return await callMdpApi('/cmn/psnz-bnnr', data);
};

/**
 * 최초 접속여부 확인
 */
export const getUserExistCheck = async data => {
  return await callMdpApi('/cmn/user-exist-check', data);
};
