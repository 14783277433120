/* eslint-disable require-await */
/********************************
 * MDP(마이데이터) API 스크립트
 * AS : Asset 자산
 ********************************/
import { callMdpApi } from '~/api/mdp';

/**
 * 연결 자산 목록 조회  /asset-mgmt/conn-asst-list
 * @returns
 */
export const connAstsListInqr = async data => {
  return callMdpApi('/asset-mgmt/conn-asst-list', data);
  /*
        connExtNcstYn         연결연장필요여부
        connEndBurExstYn      연결종료기관존재여부
        connAsstListSubListVO 연결자산정보
        - industry            업권코드내용 (bank:은행, card:카드, invest:금융투자, insu:보험, efin:전자금융, capital:할부금융,  telecom:통신, ginsu:보증보험, p2p:P2P, bond:인수채권, usury:대부)
        - industryNm          업권명
        - subIndustryCodeId   세부업권코드
        - subIndustryCodeNm   세부업권명
        - orgItgC             기관통합코드 (기관코드의 3~6자리 값 (업권은 다르지만 동일한 기관인 경우 해당값이 동일))
        - orgCode             기관코드
        - orgName             기관명
        - asstConnStatC       자산연결상태코드 (1:미연결, 2:연결, 3:만료예정, 4:연결만료)
        - endDate             종료일자
        - ddate               D-Day (자산연결상태코드가 3인 경우 사용)
        */
};

/**
 * 연결 기관 상세 정보 조회  /asset-mgmt/conn-bur-detail
 * 연결된 기관의 상세정보를 조회한다.
 * @returns
 */
export const connBurDtlInfInqr = async data => {
  return callMdpApi('/asset-mgmt/conn-bur-detail', data, true);
  /*
      asstConnStatC: '2', // 1:미연결, 2:연결, 3:만료예정, 4:연결만료
      isScheduled: '0', // 자동업데이트여부 0:하지않음, 1:주1회
      isConsentTransMemo: '0', // 적요 동의여부 0:미동의, 1:동의
      isConsentMerchantNameRegno: '0', // 가맹점 동의 여부 0:미동의, 1:동의
      isConsentTransCategory: '0', // 카테고리 동의여부 0:미동의, 1:동의
       connBurDetailSubVOList: [
        // 기관별 자산 정보 목록
        {
          mdAsstId: 'DTMIPTq8HCYauoDHWW0mZA==', // 자산 ID
          mdAsstSeqNo: '', // 자산 일련번호
          mdAsstNm: '개인형 IRP 상품', // 자산 명
          mdAsstVl: 'DTMIPTq8HCYauoDHWW0mZA==', // 자산 값
        },
  */
};

/**
 * 자동 업데이트 미동의 기관 목록 조회  /asset-mgmt/non-auto-update-bur-list
 * 연결된자산(기관)중자동업데이트에동의하지않은기관목록을조회한다
 * @returns
 */
export const autoUpdtDisagBurListInqr = async data => {
  return callMdpApi('/asset-mgmt/non-auto-update-bur-list', data);
};

/**
 * 민감정보 미동의 기관 목록 조회  /asset-mgmt/non-prv-agree-bur-list
 * 연결된 자산(기관)중 민감정보 선택동의에 동의하지 않은 기관목록을 조회한다.
 * @returns
 */
export const snsvInfDisagBurListInqr = async data => {
  return callMdpApi('/asset-mgmt/non-prv-agree-bur-list', data);
};

/**
 * 자산 데이터 관리 목록 조회  /asset-mgmt/asst-data-mgmt-list
 * 저장내역/삭제내역확인이가능한자산데이터관리목록을조회한다.
 * @returns
 */
export const astsDtaMngtListInqr = async data => {
  return callMdpApi('/asset-mgmt/asst-data-mgmt-list', data);
};

/**
 * 저장내역 상세정보 조회   /asset-mgmt/asst-data-detail
 * 저장내역/삭제내역의기관정보상세정보를조회한다.
 * @returns
 */
export const saveDelIzDtlInqr = async data => {
  return callMdpApi('/asset-mgmt/asst-data-detail', data);
  // return {
  //   success: true,
  //   message: '정상 처리되었습니다.',
  //   code: 'NCHPP0068',
  //   payload: {
  //     asstDataDetailInfoSubVOList: [
  //       {
  //         industry: 'bank',
  //         industryNm: '은행',
  //         orgItgC: 'AAEO',
  //         orgCode: 'A1AAEO0000',
  //         orgName: '국민은행',
  //       },
  //     ],
  //   },
  // };
};

/**
 * 서비스해지화면 정보 조회  /asset-mgmt/so-page-info
 * 서비스해지화면에 나오는 자산 정보를 조회한다.
 * @returns
 */
export const svRsgInfInqr = async data => {
  return callMdpApi('/asset-mgmt/so-page-info', data);
};

/**
 * 삭제할 자산 목록 조회  /asset-mgmt/del-asst-list
 * 개별 자산 삭제 처리를 위해 삭제할 자산 목록을 조회한다.
 * @returns
 */
export const delAsstListInqr = async data => {
  return callMdpApi('/asset-mgmt/del-asst-list', data);
};

/**
 * 기관연결해제  /asset-mgmt/org-revoke
 * 선택한 기관의 마이데이터 자산 연결을 해제한다.
 * @returns
 */
export const burConnCut = async data => {
  return callMdpApi('/asset-mgmt/org-revoke', data);
};

/**
 * 기관데이터삭제  /asset-mgmt/org-data-delete
 * 선택한 기관의 마이데이터 자산을 삭제한다.
 * @returns
 */
export const deleteOrgData = async data => {
  return callMdpApi('/asset-mgmt/org-data-delete', data);
};

/**
 * 기관데이터삭제  /asset-mgmt/cancel-org-data-delete
 * 선택한 기관의 기관 데이터 삭제를 취소한다.
 * @returns
 */
export const cancelOrgDataDelete = async data => {
  return callMdpApi('/asset-mgmt/cancel-org-data-delete', data);
};

/**
 * 마이데이터회원탈회 API, API_MB_003
 */
export const secession = async data => {
  return callMdpApi('/mydata-member/secession', data);
};
