/*************************************************
 * 디지털채널 API 호출을 위한 공통 Helper 스크립트
 *************************************************/
import Modal from '~/utils/modals';
import CommonUtil from '~/utils/common-util';
import StringUtil from '~/utils/string-util';
import StorageUtil from '~/utils/storage-util';

let txSeq = 0; // 거래순번

/**
 * 디채 scard.common.js 로직
 * @returns
 */
// 표준전문생성시스템명 STD_ETXT_CRT_SYS_NM
// PC인 경우 : P + [로그인ID] 로 세팅,  로그인ID는 7자리로 자름 => 로그인ID는 hash reverse 후 7자리를 사용
// Mobile인 경우 : M + [로그인ID] 로 세팅,  로그인ID는 7자리로 자름 => 로그인ID는 hash reverse 후 7자리를 사용
// MobileApp 인 경우 : A + [로그인ID] 로 세팅,  로그인ID는 7자리로 자름 => 로그인ID는 hash reverse 후 7자리를 사용
// 미로그인시 : P + "usid0" 또는 M + "usid0"
// 'stdEtxtCrtSysNm' : ((that.isMobile() == true) ? 'M' : 'P') + that.padString(env.usid, 7),
export const makeStdEtxtCrtSysNm = () => {
  const usid = StorageUtil?.store?.get('loginUser')?.mnmCstMngtNo ?? 'USERID0';
  return (
    (CommonUtil.isMobile() ? 'M' : 'P') +
    (usid === 'USERID0'
      ? StringUtil.padString(StringUtil.reverse(StringUtil.hash(Math.random() + '') + ''), 7)
      : StringUtil.padString(StringUtil.reverse(StringUtil.hash(usid) + ''), 7))
  );
};

/**
 * 디지털채널 API 호출 공통부(common)를 생성하여 리턴한다.
 * @param {String} svId 서비스ID
 * @param {Object} data 요청 데이터
 * @param {Boolean} hasPrivateInfo 개인정보포함여부
 * @param {Object} context dayjs 인스턴스가 주입되어 있는 Nuxt.js Context or Vue인스턴스 VM(Vue Model)
 * @returns 디지털채널 API 호출 공통부(common)
 */
export const makeChannelCommon = (svId, data, hasPrivateInfo = false, { $dayjs = window?.$nuxt?.$dayjs }) => {
  const yyyyMMddHHmmssSSS = $dayjs().format('YYYYMMDDHHmmssSSS');
  return {
    ...data.common,
    dlngEvnDvC: svId?.substr(10, 1) || 'S',
    scrnId: CommonUtil.getScrnId(),
    stdEtxtCrtDt: yyyyMMddHHmmssSSS.substr(0, 8),
    stdEtxtCrtSysNm: makeStdEtxtCrtSysNm(),
    stdEtxtSn: yyyyMMddHHmmssSSS.substr(8) + StringUtil.padString(++txSeq, 5, '0'),
    stdEtxtPrgDvNo: 0,
    stdEtxtPrgNo: 0,
    indvInfIncYn: hasPrivateInfo ? 'Y' : 'N', // 개인정보포함여부 (For ActionLog) INDV_INF_INC_YN 	: 'Y'/'N'
    usid: StorageUtil?.store?.get('loginUser')?.mnmCstMngtNo ?? 'USERID0',
  };
};

/**
 * 디지털채널 API 호출 공통부(common)를 포함한 전체 요청데이터 생성
 * @param {String} svId 서비스ID
 * @param {Object} data 요청 데이터
 * @param {Boolean} hasPrivateInfo 개인정보포함여부
 * @param {Object} context axios 인스턴스가 주입되어 있는 Nuxt.js Context or Vue인스턴스 VM(Vue Model)
 * @returns 디지털채널 API 호출 스펙에 맞는 API 요청데이터
 */
export const makeChannelReqData = (svId, data = {}, hasPrivateInfo, context = window?.$nuxt?.context) => {
  // Deep Copy 원본 데이터 가공 방지
  const _data = CommonUtil.getCloneObject(data);
  _data.common = makeChannelCommon(svId, data, hasPrivateInfo, context);
  return _data;
};

/**
 * 디지털채널 API 응답 결과 공통 처리 핸들러
 * @param {Object} data
 * @param {Object} request API 요청 객체
 * @returns
 */
export const channelResponseHandler = (data, request) => {
  // 디지털 채널 공통부(common)의 처리결과구분코드(procsRsDvC) 가 '0'이 아닌경우 오류이다.
  if (data?.common?.procsRsDvC !== '0') {
    // 예외를 던져 에러 처리 핸들러가 catch하게 한다.
    const err = new Error(data?.message?.msgKrnCn) ?? '처리 도중 오류가 발생하였습니다.';
    err.msgC = data?.message?.msgC;
    err.msgKrnCn = data?.message?.msgKrnCn;

    // Sentry 전송을 위해 에러 객체에 요청, 응답 정보 저장
    err.request = request;
    err.response = { data };

    throw err;
  }
  return data;
};

/**
 * 디지털채널 HPP API 응답 결과 공통 처리 핸들러
 * @param {Object} data
 * @param {Object} request API 요청 객체
 * @returns
 */
export const hppResHandler = (data, request) => {
  // 정상 호출된 경우 payload 내 INVALID_CODE가 있는 경우 오류이다.
  if (data?.payload?.INVALID_CODE || !data?.success) {
    // 예외를 던져 에러 처리 핸들러가 catch하게 한다.
    const err = new Error(data?.message) ?? '처리 도중 오류가 발생하였습니다.';
    err.msgC = data?.code;
    err.msgKrnCn = data?.message;

    // Sentry 전송을 위해 에러 객체에 요청, 응답 정보 저장
    err.request = request;
    err.response = { data };

    throw err;
  }
  const resData = data?.payload;
  return resData;
};
/**
 * 디지털채널 API 통신 에러 처리 핸들러
 * @param {Object} error
 * @param {Boolean} dontHandlerErrors 공통 에러처리를 원하지 않는경우 설정 (기본값=false 로 공통에서 에러 핸들링)
 */
export const channelErrorHandler = (error, dontHandlerErrors = false, svId) => {
  // 핸들링 여부와 상관없이 Sentry 전송
  const { errorObject, errorType } = window?.$nuxt?.context.$sentryUtil.getError(error);
  window?.$nuxt?.context.$sentryUtil.captureException(errorObject, errorType);

  // 공통 에러처리를 원하지 않는경우(직접 핸들링 하고자 할 때),
  // 에러를 그대로 throw 시킨다.
  if (dontHandlerErrors) throw error;

  let alertMsg = '앗, 일시적으로<br />시스템과의 연결이 불안정합니다. <br />잠시 후 다시 확인해 주세요.';
  const { message: errorMessage } = error;

  if (error?.response) {
    const { status, data: errorData } = error?.response;

    if (status === 200) {
      alertMsg = errorMessage || errorData;
    } else if (status === 503) {
      alertMsg = '앗, 일시적으로<br />시스템과의 연결이 불안정 합니다. <br />잠시 후 다시 확인해 주세요.';
    } else {
      alertMsg = '앗, 일시적으로<br />시스템과의 연결이 불안정합니다. <br />잠시 후 다시 확인해 주세요.';
    }
    // } else {
    //   alertMsg = `${status} [${statusText}]` || errorData || errorMessage;
    // }
  } else {
    alertMsg = errorMessage;
  }

  if (CommonUtil.isDev() || CommonUtil.isTest()) {
    alertMsg += `<br/>[개발 디버그 정보]<br/>요청 서비스 ID: ${svId}`;
  }

  // 메시지 상자 열기
  const messageDialogData = {
    id: 'scLegacyMsg',
    contentText: alertMsg,
  };

  // 메시지 다이얼로그 아이콘 설정  ( I/N 에 대해서는 아이콘 삭제 처리 )
  if (error?.msgC?.startsWith('I') || error?.msgC?.startsWith('N')) {
    messageDialogData.iconName = '';
  }

  Modal.messageDialogData(messageDialogData);
};
